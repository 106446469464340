module.exports = [{
      plugin: require('/Users/Rats/Documents/Desarrollo/acromatico/clientes/rafa-mon/rafa-mon-web-page/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/Rats/Documents/Desarrollo/acromatico/clientes/rafa-mon/rafa-mon-web-page/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-140533122-1"},
    },{
      plugin: require('/Users/Rats/Documents/Desarrollo/acromatico/clientes/rafa-mon/rafa-mon-web-page/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
