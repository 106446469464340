// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/Rats/Documents/Desarrollo/acromatico/clientes/rafa-mon/rafa-mon-web-page/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/Users/Rats/Documents/Desarrollo/acromatico/clientes/rafa-mon/rafa-mon-web-page/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-historia-js": () => import("/Users/Rats/Documents/Desarrollo/acromatico/clientes/rafa-mon/rafa-mon-web-page/src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("/Users/Rats/Documents/Desarrollo/acromatico/clientes/rafa-mon/rafa-mon-web-page/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informacion-js": () => import("/Users/Rats/Documents/Desarrollo/acromatico/clientes/rafa-mon/rafa-mon-web-page/src/pages/informacion.js" /* webpackChunkName: "component---src-pages-informacion-js" */),
  "component---src-pages-regalos-js": () => import("/Users/Rats/Documents/Desarrollo/acromatico/clientes/rafa-mon/rafa-mon-web-page/src/pages/regalos.js" /* webpackChunkName: "component---src-pages-regalos-js" */),
  "component---src-pages-rsvp-js": () => import("/Users/Rats/Documents/Desarrollo/acromatico/clientes/rafa-mon/rafa-mon-web-page/src/pages/rsvp.js" /* webpackChunkName: "component---src-pages-rsvp-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/Rats/Documents/Desarrollo/acromatico/clientes/rafa-mon/rafa-mon-web-page/.cache/data.json")

